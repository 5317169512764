import Component from './Component.vue';

const Plugin = {
    install(app, options = {}) {
        // Register the component globally
        app.component('ntriga-modal', Component);

        // Initialize event bus
        Plugin.events = new (class {
            constructor() {
                this.events = {};
            }

            $on(event, callback) {
                if (!this.events[event]) {
                    this.events[event] = [];
                }
                this.events[event].push(callback);
            }

            $emit(event, ...args) {
                if (this.events[event]) {
                    this.events[event].forEach(callback => callback(...args));
                }
            }
        })();

        // Add $ntrigaModal to global properties
        app.config.globalProperties.$ntrigaModal = {
            show(name, params = {}) {
                location.hash = name;
                Plugin.events.$emit('show', params);
            },

            hide(name) {
                location.hash = '#';
            },

            dialog(message, params = {}) {
                if (typeof message === 'string') {
                    params.message = message;
                } else {
                    params = message;
                }

                return new Promise((resolve, reject) => {
                    this.show('dialog', params);

                    Plugin.events.$on(
                        'clicked', confirmed => resolve(confirmed)
                    );
                });
            }
        };
    }
};

export default Plugin;
